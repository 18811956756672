import React from "react";
import { Link } from "react-router-dom";

export default function Top() {
  return (
    <>
      <section className="bg-center bg-no-repeat bg-cover bg-gray-400 bg-blend-multiply" style={{ backgroundImage: `url(/images/Index-Top-Image.png)` }}>
          <div className="px-2 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
              <div className="font-serif mb-4 text-lg text-gray-300 lg:text-xl sm:px-16 lg:px-48">
              <p>私達の使命は </p>
              <br />
              <p>茶々坊で、タピオカで</p>
              <p>お客様に笑顔になってもらうこと</p>
              <p>元気になっていただくこと </p>
              <br />
              <p>タピオカ屋ではなく</p>
              <p>茶々坊として</p>
              <br/>
              <p>最高級の食材を使い、ご注文を頂いてから1杯ずつ、丹精込めて</p>
              <p>ぜひご賞味ください</p>
              </div>
              <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
                <Link to="/commitment" className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">こだわりをみる</Link> 
                <Link to="/menu" className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400">メニューをみる</Link> 
            </div>
          </div>
      </section>
    </>
  );
}