import React from "react";

export default function MenuContent() {
  return (
    <div className="font-serif py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div>
        <div className="text-center font-serif text-lg p-8 my-4 rounded-lg">
          <h3 className="text-2xl font-bold mb-4">店舗や時期により、取扱商品が異なります。</h3>
          <h3 className="text-2xl font-bold mb-4">詳細は各店舗のメニューをご覧ください。</h3>
        </div>
        <div className="max-w-screen-md mb-8 lg:mb-16">
          <h2 className="text-center text-lg font-bold text-gray-900 mb-4">メニュー</h2>
        </div>
        <div className="max-w-screen-md mx-auto mb-8 lg:mb-16">
          <h3 className="text-center text-lg font-bold text-gray-900 mb-4">【TAPIOCA MILK TEA】</h3>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 items-center justify-center">
          {['鉄観音烏龍ミルクティー', '特選タピオカミルクティー', 'ジャスミンミルクティー'].map((item, index) => (
            <div key={index} className="mx-auto max-w-xl">
              <div className="bg-white shadow-md border border-gray-200 rounded-lg w-full md:w-80 h-full md:h-350 dark:bg-gray-800 dark:border-gray-700 flex flex-col">
                <a href="#">
                  <img className="rounded-t-lg w-full h-1/2 md:h-100 object-cover" src={`/images/tapioca-milk-tea-${index+1}.png`} alt="" />
                </a>
                <div className="p-5 flex flex-col justify-between flex-grow">
                  <a href="#">
                    <h5 className="text-gray-900 font-bold text-base tracking-tight mb-2 dark:text-white">{item}</h5>
                  </a>
                  <p className="font-normal text-gray-700 mb-3 dark:text-gray-400 text-ellipsis overflow-hidden h-24">
                    {item === '鉄観音烏龍ミルクティー' ? "Brownsugar Tieguanyin milk tea" :
                    item === '特選タピオカミルクティー' ? "Special tapioca milk tea" :
                    item === 'ジャスミンミルクティー' ? "Jasmine milk tea" :
                    "Mango milk tea"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className="max-w-screen-md mt-8 mb-8 lg:mb-16">
          <h3 className="text-center text-lg font-bold text-gray-900 mb-4">【TAPIOCA MILK】</h3>
          <p className="text-center text-lg font-bold text-gray-900 mb-4">SOY MILKミルク→豆乳変更無料</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 items-center justify-center">
          {['黒糖タピオカミルク', '抹茶タピオカミルク', 'チーズ・オレオ・クリーム', '濃い！ブルーベリータピオカミルク'].map((item, index) => (
            <div key={index} className="mx-auto max-w-xl">
              <div className="bg-white shadow-md border border-gray-200 rounded-lg w-full md:w-80 h-full md:h-350 dark:bg-gray-800 dark:border-gray-700 flex flex-col">
                <a href="#">
                  <img className="rounded-t-lg w-full h-1/2 md:h-100 object-cover" src={`/images/tapioca-milk-${index+1}.png`} alt="" />
                </a>
                <div className="p-5 flex flex-col justify-between flex-grow">
                  <a href="#">
                    <h5 className="text-gray-900 font-bold text-base tracking-tight mb-2 dark:text-white">{item}</h5>
                  </a>
                  <p className="font-normal text-gray-700 mb-3 dark:text-gray-400 text-ellipsis overflow-hidden h-24">
                    {item === '黒糖タピオカミルク' ? "Brownsugar tapioca milk" :
                    item === '抹茶タピオカミルク' ? "Matcha tapioca milk" :
                    item === 'チーズ・オレオ・クリーム' ? "Cheese・oreo・cream" :
                    "Rich!! Blueberry tapioca milk"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className="max-w-screen-md mt-8 mb-8 lg:mb-16">
          <h3 className="text-center text-lg font-bold text-gray-900 mb-4">【SPECIAL SERIES】</h3>
          <p className="text-center text-lg font-bold text-gray-900 mb-4">SOY MILKミルク→豆乳変更無料</p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-1 items-center justify-center">
          {['Premium生いちごタピオカミルク', 'Premium生マンゴータピオカミルク'].map((item, index) => (
            <div key={index} className="mx-auto max-w-xl">
              <div className="bg-white shadow-md border border-gray-200 rounded-lg w-full md:w-80 h-full md:h-350 dark:bg-gray-800 dark:border-gray-700 flex flex-col">
                <a href="#">
                  <img className="rounded-t-lg w-full h-1/2 md:h-100 object-cover" src={`/images/premium-${index+1}.png`} alt="" />
                </a>
                <div className="p-5 flex flex-col justify-between flex-grow">
                  <a href="#">
                    <h5 className="text-gray-900 font-bold text-base tracking-tight mb-2 dark:text-white">{item}</h5>
                  </a>
                  <p className="font-normal text-gray-700 mb-3 dark:text-gray-400 text-ellipsis overflow-hidden h-24">
                    {item === 'Premium生いちごタピオカミルク' ? "Fresh Strawberry tapioca milk" :
                    item === 'Premium生マンゴータピオカミルク' ? "Fresh mango tapioca milk" :
                    "Rich!! Blueberry tapioca milk"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className="max-w-screen-md mt-8 mb-8 lg:mb-16">
          <h3 className="text-center text-lg font-bold text-gray-900 mb-4">【FLAVORED TEA SERIES】</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 items-center justify-center">
          {['ジャスミンタピオカティー', '白桃アールグレイティー', 'パイン＆ピンクグレープジャスミンティー', 'パイン＆ピンクグレープアールグレイティー'].map((item, index) => (
            <div key={index} className="mx-auto max-w-xl">
              <div className="bg-white shadow-md border border-gray-200 rounded-lg w-full md:w-110 h-full md:h-350 dark:bg-gray-800 dark:border-gray-700 flex flex-col">
                <a>
                  <img className="rounded-t-lg w-full h-1/2 md:h-100 object-cover" src={`/images/flavored-${index+1}.png`} alt="" />
                </a>
                <div className="p-5 flex flex-col justify-between flex-grow">
                  <a>
                    <h5 className="text-gray-900 font-bold text-sm tracking-tight mb-2 dark:text-white">{item}</h5>
                  </a>
                  <p className="font-normal text-gray-700 mb-3 dark:text-gray-400 text-ellipsis overflow-hidden h-24">
                    {item === 'ジャスミンタピオカティー' ? "Jasmine tapioca tea" :
                    item === '白桃アールグレイティー' ? "White peach Earl gray tea" :
                    item === 'パイン＆ピンクグレープジャスミンティー' ? "Pine & Pink grapefruit Jasmine tea" :
                    "Pine & Pink grapefruit Earl gray tea"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <section className="mt-5 bg-white dark:bg-gray-900">
        <div className="font-serif py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="max-w-screen-md mb-8 lg:mb-16">
          <h2 className="text-center text-lg font-bold text-gray-900 mb-4">【CUSTOM】</h2>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-12 md:space-y-0">
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className="max-w-full max-h-full object-contain" src="/images/custom-1.png" alt="沖縄県の高級黒蜜の画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">氷の量</h3>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className=" max-w-full max-h-full object-contain" src="/images/custom-2.png" alt="台湾産タピオカの画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">無し</h3>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className="max-w-full max-h-full object-contain" src="/images/custom-3.png" alt="最高級の生いちごの画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">少なめ</h3>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className=" max-w-full max-h-full object-contain" src="/images/custom-4.png" alt="最高級の生いちごの画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">普通</h3>
            </div>
          </div>

          <div className="mt-10 space-y-8 md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-12 md:space-y-0">
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className="max-w-full max-h-full object-contain" src="/images/custom-5.png" alt="沖縄県の高級黒蜜の画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">甘さ</h3>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className=" max-w-full max-h-full object-contain" src="/images/custom-6.png" alt="台湾産タピオカの画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">控えめ</h3>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className="max-w-full max-h-full object-contain" src="/images/custom-7.png" alt="最高級の生いちごの画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">普通</h3>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className=" max-w-full max-h-full object-contain" src="/images/custom-8.png" alt="最高級の生いちごの画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">甘め</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 bg-white dark:bg-gray-900">
        <div className="font-serif py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="max-w-screen-md mb-8 lg:mb-16">
          <h2 className="text-center text-lg font-bold text-gray-900 mb-4">【TOPPING】</h2>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-2 md:gap-10 md:space-y-0">
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className="max-w-full max-h-full object-contain" src="/images/topping-1.png" alt="沖縄県の高級黒蜜の画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">ナタデココ</h3>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className=" max-w-full max-h-full object-contain" src="/images/topping-2.png" alt="台湾産タピオカの画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">チーズフォーム</h3>
            </div>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className="max-w-full max-h-full object-contain" src="/images/topping-1.png" alt="沖縄県の高級黒蜜の画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">タピオカ増量</h3>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className=" max-w-full max-h-full object-contain" src="/images/topping-2.png" alt="台湾産タピオカの画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">オレオ</h3>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className="max-w-full max-h-full object-contain" src="/images/topping-3.png" alt="最高級の生いちごの画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">杏仁</h3>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}