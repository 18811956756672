import React from 'react';

export default function CompanyContent() {
  return (
    <div className="font-serif bg-gray-100 min-h-screen p-8">
      <div className="max-w-4xl mx-auto bg-white p-6 shadow-lg">
        <div className="space-y-6">
          <h1 className="text-3xl font-bold text-center text-gray-800">株式会社レミナ</h1>
          <div className="flex justify-center">
            <img className="h-64" src="/images/chachabou-logo.jpg" alt="chacabo-logo" />
          </div>
          <div className="border-b pb-4">
            <h2 className="text-xl text-gray-600 font-semibold mb-2">会社概要</h2>
            <div className="grid grid-cols-1 gap-4 text-gray-600">
              <p><strong>所在地：</strong>東京都港区芝浦4-21-1-4404号</p>
              <p><strong>電話番号：</strong>03-6261-0611</p>
              <p><strong>事業内容：</strong>タピオカ専門店"茶々坊"の運営</p>
              <p><strong>設立日：</strong>令和1年9月</p>
              <p><strong>従業人数：</strong>45人 (アルバイト含む)</p>
              <p><strong>URL：</strong><a href="https://chachabo.jp" className="text-blue-600 hover:text-blue-800 transition duration-300">https://chachabo.jp</a></p>
              <p><strong>お問い合わせ/取材依頼：</strong><a href="mailto:info@lemina.co.jp" className="text-blue-600 hover:text-blue-800 transition duration-300">info@lemina.co.jp</a></p>
              <p><strong>取引先銀行：</strong>みずほ銀行/芝支店、三菱UFJ銀行/田町支店、三井住友銀行/三田通支店、東日本銀行/三田支店</p>
            </div>
          </div>
          <div className="bg-gray-200 p-4 rounded-lg">
            <h3 className="text-lg text-gray-700 font-bold mb-2">お問い合わせ先</h3>
              <p>ご質問、お問い合わせ、タピオカ専門店"茶々坊"への取材をご希望の方は、お気軽に上記メールアドレスまでご連絡ください。</p>
          </div>
        </div>
      </div>
    </div>
  );
}
