import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="text-gray-600 body-font">
        <div className="font-serif container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
            <a className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <img src="/images/chachabou_logo.jpeg" className="mr-3 h-6 sm:h-9" alt="茶々坊ロゴ" />
            </a>
            <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2024
            <a href="https://twitter.com/knyttneve" className="text-gray-600 ml-1" rel="noopener noreferrer" target="_blank">@chachabou</a>
            </p>
        </div>
      </footer>
    </>
  );
}