import React from "react";
import { Link } from "react-router-dom";

export default function MenuList() {
  return (
    <div className="font-serif py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div className="max-w-screen-md mb-8 lg:mb-16">
        <h2 className="text-center text-lg font-bold text-gray-900 mb-4">メニュー</h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 items-center justify-center">
        {['黒糖タピオカミルク', '抹茶タピオカミルク', 'Premium生いちごタピオカミルク', 'Premium生マンゴータピオカミルク'].map((item, index) => (
          <div key={index} className="mx-auto max-w-xl">
            <div className="bg-white shadow-md border border-gray-200 rounded-lg w-full md:w-80 h-full md:h-350 dark:bg-gray-800 dark:border-gray-700 flex flex-col">
              <a href="#">
                <img className="rounded-t-lg w-full h-1/2 md:h-100 object-cover" src={`/images/chachabou-${index+1}.png`} alt="" />
              </a>
              <div className="p-5 flex flex-col justify-between flex-grow">
                <a href="#">
                  <h5 className="text-gray-900 font-bold text-base tracking-tight mb-2 dark:text-white">{item}</h5>
                </a>
                <p className="font-normal text-gray-700 mb-3 dark:text-gray-400 text-ellipsis overflow-hidden h-24">
                  {item === '黒糖タピオカミルク' ? "沖縄県産の最高級黒蜜を利用。さらに茶々坊伝統の隠し味でコクと旨味をプラスしました！" :
                  item === '抹茶タピオカミルク' ? "京都の最高級宇治抹茶を粉から使用したTHE本格抹茶ミルクです。抹茶好きも納得の自信作です。" :
                  item === 'Premium生いちごタピオカミルク' ? "冬季限定。ジャムやシロップ不使用。完全生いちご100%使用にこだわった茶々坊自慢のPremiumな逸品。" :
                  "マンゴーの王様と呼ばれるアルフォンソマンゴーを使用。果肉をふんだんに使ってミルクと合わせた濃厚で贅沢な味わいをご堪能ください。"}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5 text-center">
        <Link to="/menu" className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-black rounded-lg border border-black hover:bg-white focus:ring-4 focus:ring-gray-400">こだわりをもっと見る</Link> 
      </div>
    </div>
  );
}