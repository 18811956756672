import React, { useState } from "react";


export default function VideoBottom() {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <video src="/videos/茶々坊動画_2.mp4" controls autoPlay muted loop style={{ width: '90%', padding: '30px' }}></video>
      </div>
    </>
  );
}