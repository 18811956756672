import React from "react";

const announcements = [
  { id: 1, date: "2023/08/04", message: "東京都狛江市に茶々坊７店舗目となる狛江店オープン致しました。" },
  { id: 2, date: "2022/11/04", message: "東京都世田谷区に茶々坊６店舗目となる千歳烏山店オープン致しました。" },
  { id: 3, date: "2022/07/17", message: "日本テレビ 上田若林の撮れ高 にて武蔵関店をご紹介していただきました。" },
  { id: 4, date: "2022/07/01", message: "茶々坊公式ホームページ開設致しました。" },
  { id: 5, date: "2024/04/02", message: "価格改定のお知らせ。", url: "/news/new-2024-04-08" } 
];

export default function NewsList() {
  const sortedAnnouncements = announcements.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).slice(0, 7);

  return (
    <div className="font-serif max-w-4xl mx-auto p-4">
      <h2 className="text-lg font-bold text-gray-900 mb-4">お知らせ一覧</h2>
      <ul>
      {sortedAnnouncements.map((announcement) => (
        <li key={announcement.id} className="bg-white shadow-lg rounded-lg p-4 mb-4">
          <p className="text-sm text-gray-500">{announcement.date}</p>
          {announcement.url ? (
            <a href={announcement.url} target="_blank" rel="noopener noreferrer" className="text-md text-blue-600 hover:underline">{announcement.message}</a>
          ) : (
            <p className="text-md text-gray-800">{announcement.message}</p>
          )}
        </li>
      ))}
      </ul>
    </div>
  );
}