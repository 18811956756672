import React from "react";

export default function RecruitmentSection() {
  return (
    <div className="font-serif bg-gray-50">
      <section className="text-gray-700 body-font">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img className="object-cover object-center rounded" alt="hero" src="/images/recruitment-1.png" />
          </div>
          <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">茶々坊とは</h1>
            <p className="mb-8 leading-relaxed">
              私たちの使命は、茶々坊でタピオカでお客様に笑顔になってもらうこと、元気になってもらうことです。こだわりとホスピタリティーを大切にしながら、高級感と落ち着きのあるスタイリッシュなブランドを確立し、これからも店舗展開をしてまいります。あなたも一緒に、茶々坊を全国へ広めて行きませんか？
            </p>
            <button 
            className="flex mx-auto mt-16 text-white bg-green-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            onClick={() => window.location.href="https://line.me/R/ti/p/%40jxz2211y"}
          >
            茶々坊採用専用LINEはこちら
          </button>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="text-center mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">求める人材像</h1>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500">
              私たちは、笑顔が素敵で、接客が好きな方、タピオカが好きな方、そして成長したいと考えている方を求めています。調理経験、飲食店での接客経験、レジ業務の経験、店舗管理やマネジメント業務の経験がある方を優遇いたします。
            </p>
            <div className="flex mt-6 justify-center">
              <div className="w-16 h-1 rounded-full bg-green-500 inline-flex"></div>
            </div>
          </div>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
            <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-5 flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">接客が好きな方</h2>
                <p className="leading-relaxed text-base">お客様とのやり取りを楽しめる方、明るい対応ができる方を求めています。</p>
              </div>
            </div>
            <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-5 flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                  <circle cx="6" cy="6" r="3"></circle>
                  <circle cx="6" cy="18" r="3"></circle>
                  <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">学びを求める方</h2>
                <p className="leading-relaxed text-base">スキルアップを目指し、積極的に学びたいと考えている方を歓迎します。</p>
              </div>
            </div>
            <div className="p-4 md:w-1/3 flex flex-col text-center items-center">
              <div className="w-20 h-20 inline-flex items-center justify-center rounded-full bg-green-100 text-green-500 mb-5 flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">調理経験がある方</h2>
                <p className="leading-relaxed text-base">キッチンでの経験があり、新しいメニュー開発に興味がある方を求めています。</p>
              </div>
            </div>
          </div>
          <button 
            className="flex mx-auto mt-16 text-white bg-green-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            onClick={() => window.location.href="https://line.me/R/ti/p/%40jxz2211y"}
          >
            茶々坊採用専用LINEはこちら
          </button>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="/images/recruitment-2.png" alt="part-time jobs" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">アルバイトスタッフ</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">楽しく元気に！</h1>
                  <p className="leading-relaxed mb-3">
                    お仕事をしてくださる方を募集してます！あなたの成長が、お客様を笑顔と茶々坊の成長に繋がる大切な役割です。ドリンク作成やレジ対応はもちろん、仕込みもお任せしていきます。時給1050円〜1400円(店舗により異なる)
                  </p>
                  <div className="flex items-center flex-wrap ">
                  <button 
                    className="flex mx-auto mt-16 text-white bg-green-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                    onClick={() => window.location.href="https://line.me/R/ti/p/%40jxz2211y"}
                  >
                    茶々坊採用専用LINEはこちら
                  </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="/images/recruitment-3.png" alt="manager" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">ストアマネージャー候補</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">"茶々坊"の最前線に立って</h1>
                  <p className="leading-relaxed mb-3">
                    活躍してくださる方をお待ちしています。店舗運営全般をお任せします。お客様対応はもちろん、在庫管理や発注業務、QSCの向上、シフトマネジメントなどを行います。
                  </p>
                  <div className="flex items-center flex-wrap">
                  <button 
                    className="flex mx-auto mt-16 text-white bg-green-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                    onClick={() => window.location.href="https://line.me/R/ti/p/%40jxz2211y"}
                  >
                    茶々坊採用専用LINEはこちら
                  </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-4 md:w-1/3">
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img className="lg:h-48 md:h-36 w-full object-cover object-center" src="/images/recruitment-4.png" alt="hiring" />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">正社員募集情報</h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">成長と機会</h1>
                  <p className="leading-relaxed mb-3">
                    雇用形態: 正社員。給与: 200,000円〜280,000円/月給。能力、経験に応じて年収を考慮します。各種会社保険完備。
                  </p>
                  <div className="flex items-center flex-wrap">
                  <button 
                    className="flex mx-auto mt-16 text-white bg-green-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                    onClick={() => window.location.href="https://line.me/R/ti/p/%40jxz2211y"}
                  >
                    茶々坊採用専用LINEはこちら
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
