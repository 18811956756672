import React from "react";

export default function NewsUpdate() {
  return (
    <div className="news-update max-w-7xl mx-auto px-4 py-8">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">価格改定のお知らせ</h2> <br />
      <p className="mb-2 text-gray-700">日頃より、茶々坊をご利用いただき誠にありがとうございます。</p><br />
      <p className="text-gray-700">この度、2024年4月8日（月）より、一部商品の価格改定をさせていただきます。</p><br />
      <p className="text-gray-700">茶々坊は、"最高級の食材を使用した美味しいタピオカドリンクをリーズナブルにご提供する" をモットーとし、日々取り組んでまいりました。</p><br />
      <p className="text-gray-700">ですがここ数年においてタピオカはもちろんのこと、ほぼ全ての食材と備品(カップ、ストロー、フィルム、袋など)の仕入れ価格、エネルギーコスト等の上昇がございました。</p> <br />
      <p className="text-gray-700">茶々坊ではもともと原価率がタピオカ店では非常に高い商品をご提供させていただいている中で、さらなる大幅な原価率アップとなりました。</p> <br />
      <p className="text-gray-700">量を減らしたり、仕入れ価格を下げる代わりに品質も落ちてしまう食材に変更することも検討しましたが、茶々坊がご提供したいタピオカドリンクとは離れることになってしまうため、そういった事もせず、</p>
      <p className="text-gray-700">店舗運営としましてはかなり以前から値上げせざるを得ない状況でございましたが、日々ご来店いただいておりますお客様へ感謝の気持ちで、ここまで一度も値上げなどをせず、なんとか耐えてまいりました。</p><br />
      <p className="text-gray-700">しかしながら、現在 東京と神奈川で７店舗 営業させていただいている中で、このままでは茶々坊のタピオカをお客様にご提供し続けることもできなくなってしまう状況となり、誠に心苦しいですが一部商品の価格改定をさせていただきます。</p> <br />
      <p className="text-gray-700">改定額は、店舗と商品により現行から50〜108円（税込み）のアップとなります。</p>
      <p className="text-gray-700">新価格については各店舗の新メニュー表をご覧ください。</p> <br />
      <p className="text-gray-700">私達の使命は⁡これからも</p><br />
      <p className="text-gray-700">茶々坊で、タピオカで⁡</p>
      <p className="text-gray-700">お客様に笑顔になってもらうこと⁡</p>
      <p className="text-gray-700">元気になっていただくこと⁡</p>
      <p className="text-gray-700">です。⁡</p><br />
      <p className="text-gray-700">“タピオカ屋” ではなく “茶々坊” として⁡</p><br />
      <p className="text-gray-700">今後も、より一層お客様にご満足いただける商品とサービスの提供に努めてまいりますので、引き続きご愛顧賜りますようお願い申し上げます。</p> <br />
    </div>
  );
}
