import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "components/organism/Header";
import Footer from "components/organism/Footer";

export default function MinamiotaekiStore() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet>
          <title>茶々坊/台湾発タピオカ専門店 | 南太田店_メニュー</title>
          <meta name="description" content="茶々坊の公式ホームページです。最高級の素材を使い、ご注文を頂いてから一杯一杯丁寧に作るドリンクは圧倒的な自信があります。最高の商品とホスピタリティーで、お客様に笑顔と元気をご提供致します。" />
          <meta property="og:title" content="茶々坊/台湾発タピオカ専門店" />
          <meta property="og:description" content="茶々坊の公式ホームページです。最高級の素材を使い、ご注文を頂いてから一杯一杯丁寧に作るドリンクは圧倒的な自信があります。最高の商品とホスピタリティーで、お客様に笑顔と元気をご提供致します。" />
          <meta property="og:url" content="https://chachabo.jp" />
          <meta property="og:type" content="website" />
          <link rel="canonical" href="https://chachabo.jp" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://chachabo.jp",
              "name": "茶々坊",
              "sameAs": [
                "https://www.instagram.com/chachabo_komae/",
                "https://www.instagram.com/chachabo_chitosekarasuyama/",
                "https://www.instagram.com/chachabo_nokendai/",
                "https://www.instagram.com/chachabo_minamiota/",
                "https://www.instagram.com/chachabo_higashimukojima/",
                "https://www.instagram.com/chachabo_musashiseki/",
                "https://www.instagram.com/chachabo_asagaya/"
              ],
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "03-6261-0611",
                "contactType": "Customer service"
              }
            })}
          </script>
        </Helmet>
      </HelmetProvider>
      <>
      <Header />
        <div className="container mx-auto p-5 px-4">
          <img src="/images/南太田店_メニュー.jpg" alt="メニュー" />
        </div>
      <Footer />
      </>
    </React.Fragment>
  );
}
