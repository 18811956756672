import React from "react";

const stores = [
  {
    name: "狛江店",
    address: "東京都狛江市東和泉1-16-8",
    phone: "080-7276-8800",
    hours: "10時00分〜22時00分",
    closed: "月〜日（定休日無し）",
    img: "/images/chachabou-store-1.jpeg",
    instagram: "https://www.instagram.com/chachabo_komae/?igshid=MzRlODBiNWFlZA%3D%3Da",
    mapLink: "https://goo.gl/maps/d8tKWV8LLE4aJgbw6",
    menuLink: "/menu/komaeshi_store"
  },
  {
    name: "千歳烏山店",
    address: "東京都世田谷区南烏山6-3-14",
    phone: "080-7013-8800",
    hours: "10時00分〜22時00分",
    closed: "月〜日（定休日無し）",
    img: "/images/chachabou-store-2.jpeg",
    instagram: "https://www.instagram.com/chachabo_chitosekarasuyama/?hl=ja",
    mapLink: "https://goo.gl/maps/ixRg24YnZtCzqebU7",
    menuLink: "/menu/titosekarasuyama_store"
  },
  {
    name: "能見台店",
    address: "神奈川県横浜市金沢区能見台通8-3",
    phone: "080-4623-8800",
    hours: "10時00分〜22時00分",
    closed: "定休日: なし",
    img: "/images/chachabou-store-3.jpeg",
    instagram: "https://www.instagram.com/chachabo_nokendai/?hl=ja",
    mapLink: "https://www.google.co.jp/maps/place/%E8%8C%B6%E3%80%85%E5%9D%8A+%E8%83%BD%E8%A6%8B%E5%8F%B0%E5%BA%97/@35.3607908,139.626074,17z/data=!3m2!4b1!5s0x60184396424a1ed5:0x39843f493f237edf!4m5!3m4!1s0x601843232903c86f:0x9ace97e46a8e22d0!8m2!3d35.3607908!4d139.6282627?hl=ja&authuser=0",
    menuLink: "/menu/noukendai_store"
  },
  {
    name: "南太田店",
    address: "神奈川県横浜市南区南太田1-22-1",
    phone: "080-7292-8800",
    hours: "10時00分〜22時00分",
    closed: "定休日: なし",
    img: "/images/chachabou-store-4.jpeg",
    instagram: "https://www.instagram.com/chachabo_minamiota/?hl=ja",
    mapLink: "https://www.google.com/maps/place/%E8%8C%B6%E3%80%85%E5%9D%8A+%E5%8D%97%E5%A4%AA%E7%94%B0%E5%BA%97/@35.4365273,139.6134681,15z/data=!4m5!3m4!1s0x0:0x43e67d622a1c064!8m2!3d35.4365313!4d139.6134762",
    menuLink: "/menu/minamiotaeki_store"
  },
  {
    name: "東向島店",
    address: "東京都墨田区東向島5-3-5",
    phone: "080-4441-8800",
    hours: "10時00分〜22時00分",
    closed: "定休日: なし",
    img: "/images/chachabou-store-5.jpeg",
    instagram: "https://www.instagram.com/chachabo_higashimukojima/?hl=ja",
    mapLink: "https://www.google.com/maps/place/%E8%8C%B6%E3%80%85%E5%9D%8A+%E6%9D%B1%E5%90%91%E5%B3%B6%E5%BA%97/@35.7251674,139.8197167,15z/data=!4m5!3m4!1s0x0:0x6a48e818adeb98ef!8m2!3d35.7251674!4d139.8197167",
    menuLink: "/menu/higasimukozima_store"
  },
  {
    name: "武蔵関店",
    address: "東京都練馬区関町北1-23-18",
    phone: "080-7604-8800",
    hours: "10時00分〜22時00分",
    closed: "定休日: なし",
    img: "/images/chachabou-store-6.jpeg",
    instagram: "https://www.instagram.com/chachabo_musashiseki/?hl=ja",
    mapLink: "https://www.google.com/maps/place/%E8%8C%B6%E3%80%85%E5%9D%8A+%E6%AD%A6%E8%94%B5%E9%96%A2%E5%BA%97/@35.7269683,139.5782079,15z/data=!4m5!3m4!1s0x0:0xe2ac32c3a5c0c8e5!8m2!3d35.7269683!4d139.5782079",
    menuLink: "/menu/musasiseki_store"
  },
  {
    name: "阿佐ヶ谷店",
    address: "東京都杉並区阿佐ヶ谷南3-38-30",
    phone: "080-4087-8800",
    hours: "14時00分〜21時00分",
    closed: "金〜日, 定休日:月〜木",
    img: "/images/chachabou-store-7.jpeg",
    instagram: "https://www.instagram.com/chachabo_asagaya/?hl=ja",
    mapLink: "https://www.google.com/maps/place/%E8%8C%B6%E3%80%85%E5%9D%8A+%E9%98%BF%E4%BD%90%E3%83%B6%E8%B0%B7%E5%BA%97/@35.704548,139.6344974,15z/data=!4m5!3m4!1s0x0:0x1b9248b3bbdb664d!8m2!3d35.704548!4d139.6344974",
    menuLink: "/menu/asagaya_store"
  }
];

export default function StoreList() {
  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="text-center font-serif text-lg p-8 my-4 rounded-lg">
          <h3 className="text-2xl font-bold mb-4">【店舗一覧】</h3>
          <h4 className="text-2xl font-bold">複数店舗ございます、是非お越しください。</h4>
        </div>
        <div className="font-serif container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4 justify-center sm:justify-start">
            {stores.map((store, index) => (
              <div key={index} className="p-4 lg:w-1/3">
                <div className="h-full flex flex-col items-center text-center">
                <img alt="store" className="w-64 h-80 object-cover mb-4 rounded" src={store.img} />
                  <h2 className="title-font font-medium text-lg">{store.name}</h2>
                  <p className="mb-3">{store.address}</p>
                  <p className="mb-4">TEL: {store.phone}</p>
                  <p className="mb-4">営業時間: {store.hours}</p>
                  <p className="mb-4">営業日: {store.closed}</p>
                  <span className="inline-flex space-x-2">
                    <a href={store.instagram} className="text-gray-500">
                      <img src="/images/instagram-icon.png" alt="" width="32" height="32" />
                    </a>
                    <a href={store.mapLink} className="text-gray-500">
                      <img src="/images/map-icon.png" alt="" width="32" height="32" />
                    </a>
                    <a href={store.menuLink} className="text-gray-500">
                      <img src="/images/menu-icon.png" alt="" width="32" height="32" />
                    </a>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
