import React from "react";
import { Routes, Route } from "react-router-dom";
import Commitment from "components/pages/Commitment";
import Menu from "components/pages/Menu";
import Index from "./components/pages/Index";
import Store from "./components/pages/Store";
import Recruitment from "components/pages/Recruitment";
import Company from "components/pages/Company";
import Delivery from "components/pages/Delivery";
import KomaeshiStore from "components/pages/menus/KomaeshiStore";
import TitosekarasuyamaStore from "components/pages/menus/TitosekarasuyamaStore";
import NoukendaiStore from "components/pages/menus/NokendaiStore"
import MinamiotaekiStore from "components/pages/menus/MinamiotaekiStore";
import HigasimukozimaStore from "components/pages/menus/HigasimukozimaStore";
import MusasisekiStore from "components/pages/menus/MusasisekiStore";
import AsagayaStore from "components/pages/menus/AsagayaStore";
import News from "components/pages/News";

function App(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/commitment" element={<Commitment />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/store" element={<Store />} />
      <Route path="/recruitment" element={<Recruitment />} />
      <Route path="/company" element={<Company />} />
      <Route path="/delivery" element={<Delivery />} />
      <Route path="/menu/komaeshi_store" element={<KomaeshiStore />} />
      <Route path="/menu/titosekarasuyama_store" element={<TitosekarasuyamaStore />} />
      <Route path="/menu/noukendai_store" element={<NoukendaiStore />} />
      <Route path="/menu/minamiotaeki_store" element={<MinamiotaekiStore />} />
      <Route path="/menu/higasimukozima_store" element={<HigasimukozimaStore />} />
      <Route path="/menu/musasiseki_store" element={<MusasisekiStore />} />
      <Route path="/menu/asagaya_store" element={<AsagayaStore />} />
      <Route path="/news/new-2024-04-08" element={<News />} />
    </Routes>
  );
}

export default App;
