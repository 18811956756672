import React from "react";
import { Link } from "react-router-dom";

export default function CommitmentList() {
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="font-serif py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="max-w-screen-md mb-8 lg:mb-16">
          <h2 className="text-center text-lg font-bold text-gray-900 mb-4">こだわり</h2>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className="rounded-full max-w-full max-h-full object-contain" src="/images/commitment-1.png" alt="沖縄県の高級黒蜜の画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">沖縄県の高級黒蜜</h3>
              <p className="text-gray-500 dark:text-gray-400">沖縄産の黒糖を使用した黒蜜だけでなく、<br />その中でも最高級のものを使用.</p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className="rounded-full max-w-full max-h-full object-contain" src="/images/commitment-2.png" alt="台湾産タピオカの画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">台湾産タピオカ</h3>
              <p className="text-gray-500 dark:text-gray-400">台湾産のタピオカ、大粒の生タピオカを使用.</p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-4 w-40 h-40 rounded-full bg-primary-100 lg:h-32 lg:w-32 dark:bg-primary-900">
                <img className="rounded-full max-w-full max-h-full object-contain" src="/images/commitment-3.png" alt="最高級の生いちごの画像" />
              </div>
              <h3 className="mb-2 text-xl font-bold dark:text-white">高級な生いちご</h3>
              <p className="text-gray-500 dark:text-gray-400">ジャムやシロップなどは使わずに<br />高級なとちおとめを生で使用.</p>
            </div>
          </div>
          <div className="mt-5 text-center">
            <Link to="/commitment" className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-black rounded-lg border border-black hover:bg-white focus:ring-4 focus:ring-gray-400">こだわりをもっと見る</Link> 
          </div>
        </div>
      </section>
    </>
  );
}
