import React, { useState } from "react";
import { Link } from 'react-router-dom';

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <header className="font-serif bg-white dark:bg-gray-800 shadow py-2.5 px-4 lg:px-6">
      <nav className="max-w-screen-xl mx-auto flex items-center justify-between">
        <a href="/" className="flex items-center">
          <img src="/images/chachabou_logo.jpeg" className="mr-3 h-6 sm:h-9" alt="茶々坊ロゴ" />
        </a>
        <div className="hidden lg:block">
          <ul className="flex space-x-4">
            <li><Link to="/" className="px-3 py-2 hover:bg-gray-50">ホーム</Link></li>
            <li><Link to="/commitment" className="px-3 py-2 hover:bg-gray-50">こだわり</Link></li>
            <li><Link to="/menu" className="px-3 py-2 hover:bg-gray-50">メニュー</Link></li>
            <li><Link to="/store" className="px-3 py-2 hover:bg-gray-50">店舗一覧</Link></li>
            <li><Link to="/delivery" className="px-3 py-2 hover:bg-gray-50">デリバリー</Link></li>
            <li><Link to="/recruitment" className="px-3 py-2 hover:bg-gray-50">求人情報</Link></li>
            <li><Link to="/company" className="px-3 py-2 hover:bg-gray-50">会社概要</Link></li>
          </ul>
        </div>

        <div>
          <button onClick={toggleMenu} className="text-gray-500 hover:text-gray-700 focus:outline-none lg:hidden">
            <span className="sr-only">Toggle navigation</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              {isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              )}
            </svg>
          </button>
        </div>

        <div className={`${isOpen ? "fixed" : "hidden"} flex-col absolute right-0 top-0 w-[75vw] h-full bg-white dark:bg-gray-800 shadow-md z-50 transform transition-transform duration-500 ease-in-out ${isOpen ? "translate-x-0" : "translate-x-full"}`}>
          <ul className="flex flex-col list-none mt-16 space-y-4 text-2xl">
            <li><Link to="/" className="px-3 py-2 hover:bg-gray-50">ホーム</Link></li>
            <li><Link to="/commitment" className="px-3 py-2 hover:bg-gray-50">こだわり</Link></li>
            <li><Link to="/menu" className="px-3 py-2 hover:bg-gray-50">メニュー</Link></li>
            <li><Link to="/store" className="px-3 py-2 hover:bg-gray-50">店舗一覧</Link></li>
            <li><Link to="/delivery" className="px-3 py-2 hover:bg-gray-50">デリバリー</Link></li>
            <li><Link to="/recruitment" className="px-3 py-2 hover:bg-gray-50">求人情報</Link></li>
            <li><Link to="/company" className="px-3 py-2 hover:bg-gray-50">会社概要</Link></li>
          </ul>
        </div>
        <div className={`${isOpen ? 'bg-black bg-opacity-50 fixed inset-0 z-40' : 'hidden'}`} onClick={toggleMenu}></div>
      </nav>
    </header>
  );
}