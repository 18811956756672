import React from 'react';

export default function DeliveryContent() {
  return (
    <div className="text-gray-600 body-font">
      <div className="font-serif container mx-auto px-5 py-24">

        <div className="flex flex-wrap items-center mb-20">
          <div className="lg:w-1/2 w-full lg:mb-0 mb-10">
            <img className="object-cover object-center rounded" alt="delivery" src="/images/uber-image.jpg" />
          </div>
          <div className="lg:w-1/2 w-full p-10">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">茶々坊のデリバリー</h1>
            <p className="mb-2 leading-relaxed">茶々坊のドリンクメニューをお楽しみいただけるようデリバリーサービスを拡大しております。</p>
            <p className="mb-2 leading-relaxed">お陰様で茶々坊は、お客様からの評判はもちろんUberEatsデリバリーの様々な観点から総合評価 "Eats厳選"にも選出されており、同カテゴリー数十店舗の中でトップの評価をいただいております！</p>
          </div>
        </div>

        <div className="flex flex-wrap items-center mb-20 flex-row-reverse">
          <div className="lg:w-1/2 w-full lg:mb-0 mb-10">
            <img className="object-cover object-center rounded" alt="team" src="/images/delivery-2.png" />
          </div>
          <div className="lg:w-1/2 w-full">
            <h2 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-gray-900">デリバリー限定のお得な5個セット！</h2>
            <p className="mb-8 leading-relaxed">セットでご注文いただくとお得に茶々坊をお楽しみ頂けます。 <br />
            ミーティングやおうち時間のお供にぴったりです。</p>
          </div>
        </div>

        <section className="text-gray-600 body-font">
          <div className="text-center max-w-screen-md">
            <h2 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-gray-900">すでにアプリをお持ちの方、パソコンの方はこちら</h2>
          </div>
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap -m-4">
              <div className="p-4 md:w-1/2">
                <div className="h-full bg-white border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <img className="lg:h-48 md:h-36 w-full object-contain object-center" src="/images/UberEats-logo.png" alt="UberEats logo" />
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">DELIVERY SERVICE</h2>
                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3">UberEats</h1>
                    <p className="leading-relaxed mb-3">UberEatsでデリバリーできる店舗を探す</p>
                    <div className="flex items-center flex-wrap">
                      <a href="https://www.google.co.jp/maps/search/%E8%8C%B6%E3%80%85%E5%9D%8A/" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">こちらをクリック
                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path d="M5 12h14"></path>
                          <path d="M12 5l7 7-7 7"></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/2">
                <div className="h-full bg-white border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <img className="lg:h-48 md:h-36 w-full object-contain object-center" src="/images/demaekan-logo.png" alt="Demae-can logo" />
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">DELIVERY SERVICE</h2>
                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Demae-can</h1>
                    <p className="leading-relaxed mb-3">出前館でデリバリーできる店舗を探す</p>
                    <div className="flex items-center flex-wrap">
                      <a href="https://www.google.co.jp/maps/search/%E8%8C%B6%E3%80%85%E5%9D%8A/" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">こちらをクリック
                        <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path d="M5 12h14"></path>
                          <path d="M12 5l7 7-7 7"></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="text-gray-600 body-font">
          <div className="text-center max-w-screen-md">
            <h2 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-gray-900">デリバリーアプリをダウンロード</h2>
          </div>
          <div className="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap -m-4">
              <div className="p-4 md:w-1/2">
                <div className="h-full bg-white border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <img className="lg:h-48 md:h-36 w-full object-contain object-center" src="/images/UberEats-logo.png" alt="UberEats logo" />
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">DELIVERY SERVICE</h2>
                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3">UberEats</h1>
                    <div className="flex items-center flex-wrap">
                      <div className="border-opacity-60 rounded-lg overflow-hidden sm:w-1/2 w-full">
                      <a href="https://apps.apple.com/jp/app/id1058959277" target="_blank" rel="noopener noreferrer">
                        <img className="lg:h-48 md:h-36 w-auto object-contain object-center" src="/images/appstore-logo-img.png" alt="App Store logo" />
                      </a>
                      </div>
                      <div className="border-opacity-60 rounded-lg overflow-hidden sm:w-1/2 w-full">
                      <a href="https://play.google.com/store/apps/details?id=com.ubercab.eats" target="_blank" rel="noopener noreferrer">
                        <img className="lg:h-48 md:h-36 w-auto object-contain object-center" src="/images/google-play-logo.png" alt="Google Play logo" />
                      </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/2">
                <div className="h-full bg-white border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                  <img className="lg:h-48 md:h-36 w-full object-contain object-center" src="/images/demaekan-logo.png" alt="Demae-can logo" />
                  <div className="p-6">
                    <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">DELIVERY SERVICE</h2>
                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3">Demae-can</h1>
                    <div className="flex items-center flex-wrap">
                      <div className="border-opacity-60 rounded-lg overflow-hidden sm:w-1/2 w-full">
                        <a href="https://apps.apple.com/jp/app/id405489166" target="_blank" rel="noopener noreferrer">
                          <img className="lg:h-48 md:h-36 w-auto object-contain object-center" src="/images/appstore-logo-img.png" alt="App Store logo" />
                        </a>
                        </div>
                        <div className="border-opacity-60 rounded-lg overflow-hidden sm:w-1/2 w-full">
                        <a href="https://play.google.com/store/apps/details?id=com.demaecan.androidapp" target="_blank" rel="noopener noreferrer">
                          <img className="lg:h-48 md:h-36 w-auto object-contain object-center" src="/images/google-play-logo.png" alt="Google Play logo" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
