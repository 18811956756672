import React from "react";

export default function CommitmentContent() {
  return (
    <>
      <section className="text-gray-600 bg-white body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="text-center font-serif text-lg p-8 my-4 rounded-lg">
            <h2 className="text-2xl font-bold mb-4">【茶々坊のこだわり】</h2>
            <p>タピオカ屋 ではなく 茶々坊として</p>
            <p>美味しさに圧倒的な自信</p>
            <p>最高級の食材を使い、ご注文頂いてから一杯ずつ、</p>
            <p>丹精込めてお作りいたします。</p>
            <p className="font-bold mt-4">ぜひご賞味ください。</p>
          </div>
          <div className="font-serif grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="p-4">
              <div className="h-full text-center">
                <div className="h-64 overflow-hidden">
                  <img alt="タピオカ" className="mx-auto object-scale-down h-full w-auto" src="/images/commitment-img-1.png" />
                </div>
                <h3 className="text-lg leading-relaxed mt-4">タピオカ</h3>
                <p className="leading-relaxed">台湾産のタピオカ、<br />大粒の生タピオカを使用</p>
              </div>
            </div>

            <div className="p-4">
              <div className="h-full text-center">
                <div className="h-64 overflow-hidden">
                  <img alt="黒糖" className="mx-auto object-scale-down h-full w-auto" src="/images/commitment-img-2.png" />
                </div>
                <h3 className="text-lg leading-relaxed mt-4">黒糖</h3>
                <p className="leading-relaxed">沖縄産の黒糖を使用した黒蜜 <br />その中でも最高級のものを使用</p>
              </div>
            </div>

            <div className="p-4">
              <div className="h-full text-center">
                <div className="h-64 overflow-hidden">
                  <img alt="抹茶" className="mx-auto object-scale-down h-full w-auto" src="/images/commitment-img-3.png" />
                </div>
                <h3 className="text-lg leading-relaxed mt-4">抹茶</h3>
                <p className="leading-relaxed">抹茶シロップなどを使わずに、<br />京都の高級宇治抹茶を各店舗で粉から使用</p>
              </div>
            </div>

            <div className="p-4">
              <div className="h-full text-center">
                <div className="h-64 overflow-hidden">
                  <img alt="ミルクティー" className="mx-auto object-scale-down h-full w-auto" src="/images/commitment-img-4.png" />
                </div>
                <h3 className="text-lg leading-relaxed mt-4">ミルクティー</h3>
                <p className="leading-relaxed">ミルクティーに最適と言われる <br />アッサム産の茶葉を使用</p>
              </div>
            </div>

            <div className="p-4">
              <div className="h-full text-center">
                <div className="h-64 overflow-hidden">
                  <img alt="いちご" className="mx-auto object-scale-down h-full w-auto" src="/images/commitment-img-5.png" />
                </div>
                <h3 className="text-lg leading-relaxed mt-4">いちご</h3>
                <p className="leading-relaxed">ジャムやシロップなどを使わずに、<br />高級ないちごを生で使用</p>
              </div>
            </div>

            <div className="p-4">
              <div className="h-full text-center">
                <div className="h-64 overflow-hidden">
                  <img alt="マンゴー" className="mx-auto object-scale-down h-full w-auto" src="/images/commitment-img-6.png" />
                </div>
                <h3 className="text-lg leading-relaxed mt-4">マンゴー</h3>
                <p className="leading-relaxed">マンゴーの王様と言われ、<br />マンゴーの最高級品と扱われる <br />アルフォンソマンゴーを使用</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
